import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure 
} from '@chakra-ui/react'
import ModalButton from '../Buttons/ModalButton'
import NewsletterForm from './NewsletterForm'

export default function NewsletterModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // fixes scroll to top when modal closes
    const finalRef = React.useRef()
    return (
        <>
            <ModalButton
                onClick={onOpen}
                size={props.size}
                width={props.width}
                colorScheme={props.colorScheme}
                btnText={props.btnText}
                ref={finalRef}
                isCentered  
            />

            <Modal isOpen={ isOpen } onClose={ onClose } size="xl" isCentered finalFocusRef={finalRef}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader color="brand.400">{props.modalTitle}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <iframe src="https://tetonvalleyadvocates.dm.networkforgood.com/forms/107661?iframe=1&color=323F07" width="100%" height="550" frameborder="0"></iframe>
                        {/* <NewsletterForm /> */}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

