import React from 'react'
import { Box,
        Heading,
        Link,
        Flex,
        Text,
        Container,
        Tooltip,
        chakra } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import FacebookLogo from './FacebookLogo'
import NewsletterModal from '../NewsletterSignupBlock/NewsletterModal'
import DonateButton from '../Buttons/DonateButton'
import CalderaCreative from './CalderaCreative'
import { ExternalLinkIcon } from '@chakra-ui/icons'


const Footer = () => {

    return (
        <Container maxW="100%"
            backgroundColor="brand.400"
            p="8"
            color="white"
            mb="20"
            as="footer"
        >
            <Flex mb="4" flexWrap="wrap">
            <Box w={{sm: '100%', md: '40%'}} mb={{sm: '2rem', md: 0}} textAlign={{sm: 'center', md: 'left'}}>
                <Heading as="h4" size="md" mb="2">
                    Contact
                </Heading>
                <Text>
                    <strong>Niki Richards</strong>, Executive Director<br />
                    <Link href="tel:208-354-1707" color="sunset.300">(208) 354-1707</Link><br />
                    85 Depot St. Suite 2<br />
                    PO Box 1164<br />
                    Driggs, ID 83422
                </Text>
            </Box>
            <Box w={{sm: '100%', md: '20%'}} mb={{sm: '2rem', md: 0}} textAlign={{sm: 'center', md: 'left'}}>
                <Heading as="h4" size="md" mb="2">
                    Menu
                </Heading>
                <Text textTransform="uppercase" mb="0.25rem">
                    <Link to="/about" as={GatsbyLink}  _hover={{textDecoration: 'none'}}>
                        About
                    </Link>
                </Text>
                <Text textTransform="uppercase" mb="0.25rem">
                    <Link to="/our-work" as={GatsbyLink}  _hover={{textDecoration: 'none'}}>
                        Our Work
                    </Link>
                </Text>
                <Text textTransform="uppercase" mb="0.25rem">
                    <Link to="/action" as={GatsbyLink}  _hover={{textDecoration: 'none'}}>
                        Action
                    </Link>
                </Text>
                <Text textTransform="uppercase" mb="0.25rem">
                    <Link to="/resources" as={GatsbyLink}  _hover={{textDecoration: 'none'}}>
                        Resources
                    </Link>
                </Text>
                <Text textTransform="uppercase" mb="0.25rem">
                    <Link to="/news" as={GatsbyLink}  _hover={{textDecoration: 'none'}}>
                        News
                    </Link>
                </Text>
                <Text textTransform="uppercase" mb="0.25rem">
                    <Link to="/contact" as={GatsbyLink}  _hover={{textDecoration: 'none'}}>
                        Contact
                    </Link>
                </Text>
                <Tooltip hasArrow label="View our old website">
                    <Text textTransform="uppercase" mb="0.25rem">
                        <Link href="https://archive.tetonvalleyadvocates.org" _hover={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer">
                            Website Archive <ExternalLinkIcon style={{marginBottom: '4px'}}/>
                        </Link>
                    </Text>
                </Tooltip>
            </Box>
            <Box w={{sm: '100%', md: '33%'}} mb={{sm: '2rem', md: 0}}>
                <Heading as="h4" size="md">
                    Follow Us On
                </Heading>
                <FacebookLogo />
                <Box my="4">
                <NewsletterModal 
                    size="md"
                    width="100%"
                    colorScheme="sunset"
                    btnText="Newsletter Signup"
                    modalTitle="Sign Up For Our Newsletter"
                />
                </Box>
                <Box my="4">
                    <DonateButton 
                        width="100%"
                        btnText="Donate"
                        colorScheme="steel"
                    />
                </Box>
                
            </Box>
            </Flex>
            <hr />
            <Flex w="100%" pt="6" justifyContent={{base: 'center', md: 'flex-end'}} flexWrap="wrap">
                <Box><Text>&copy; Valley Advocates For Responsible Development, {new Date().getFullYear()} | <Link as={GatsbyLink} to="/privacy-policy" color="sunset.300">Privacy Policy</Link> <chakra.span display={{base: 'none', md: 'inline-block'}}>|</chakra.span> </Text></Box>
                <Box><CalderaCreative /></Box>
            </Flex>
        </Container>
    )
}

export default Footer