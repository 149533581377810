import React from 'react'
import { Button } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

const CtaButton = (props) => (
    <>
    <GatsbyLink to={props.linkTo}>
    <Button 
        width={props.width}
        size={props.size}
        colorScheme={props.colorScheme}
        textTransform="uppercase"
        > 
        {props.btnText}
    </Button>
    </GatsbyLink>
    </>  
)

export default CtaButton