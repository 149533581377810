import React from 'react'
import styled from '@emotion/styled'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '200px' };
  height: auto;
  .cls-1 {
    fill: #fff;
      }
`;

const TwentyYearsStrong = props => {
    return (

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 921.78713 308.73004" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <path className="cls-1" d="M245.94975,299.95826H0V251.002a276.25178,276.25178,0,0,1,35.33434-35.567,356.29234,356.29234,0,0,1,38.973-28.53588q21.13221-13.24836,35.44726-23.6673,14.31607-10.40837,22.49879-21.77272a67.02312,67.02312,0,0,0,11.70214-22.99049,102.27662,102.27662,0,0,0,3.5236-27.85907q0-34.3455-13.65527-50.03812Q120.16644,24.88882,97.64047,24.88382a69.55072,69.55072,0,0,0-13.31233,1.21777,55.11175,55.11175,0,0,0-13.08439,4.46224q3.40336,12.17144,6.57879,25.1543a121.87947,121.87947,0,0,1,3.17856,28.941q0,19.2088-11.57248,30.56383-11.57143,11.36-29.49582,11.36-17.01683,0-27.114-13.25334Q2.72165,100.08123,2.72269,81.1429q0-32.99562,32.21851-57.07051Q67.15553.00249,113.44546,0q53.54416,0,86.5594,21.90858,33.01107,21.9086,33.01106,70.59433,0,25.1543-8.73478,40.57145-8.73582,15.41715-25.75264,27.04763a147.6997,147.6997,0,0,1-33.69277,17.31049q-18.49424,6.7644-39.592,12.98286a315.54436,315.54436,0,0,0-39.13816,14.33525A134.614,134.614,0,0,0,56.49688,222.8725v2.97524H245.94975Z"/>
          <path className="cls-1" d="M413.67859,285.47625c-1.129.09426-2.27466.14676-3.441.14676A35.4947,35.4947,0,0,1,383.91824,274.804q-10.66491-10.81407-16.33614-28.4a204.54745,204.54745,0,0,1-8.73478-40.57145,465.61783,465.61783,0,0,1-2.38183-51.12,467.71588,467.71588,0,0,1,2.72269-52.60828q2.7227-23.93528,8.62186-40.43558,6.57774-18.11694,17.01577-27.99368,10.43594-9.87177,25.86765-9.873,15.19752,0,25.63763,10.00762,10.4328,10.01012,16.78993,28.4a193.35025,193.35025,0,0,1,8.73478,40.84192,462.0372,462.0372,0,0,1,2.60976,51.661q0,26.101-1.90792,45.74041c23.01192-42.26909,45.52849-85.26564,70.15556-126.30559a141.37177,141.37177,0,0,0-16.51651-26.273q-17.01681-21.36264-43.563-34.621Q446.08741.005,410.46553,0q-34.4916,0-61.0347,12.847-26.5431,12.85013-43.7889,34.21588A147.82738,147.82738,0,0,0,279.4355,96.0191q-8.50684,27.05013-8.50684,58.69337a194.01565,194.01565,0,0,0,8.96062,59.77527Q288.851,242.3493,306.09571,262.903a130.33663,130.33663,0,0,0,43.676,33.53907q21.69057,10.51936,48.9261,12.288Q406.33772,297.26955,413.67859,285.47625Z"/>
        </g>
        <g>
          <path className="cls-1" d="M431.5884,260.1013v27.09375H417.59523V260.1013l-15.95361-48.13086h15.41895l7.57568,31.13135h.17871l7.66455-31.13135h15.15186Z"/>
          <path className="cls-1" d="M452.17629,287.19505V211.97044h30.48193v14.98145h-16.8457v14.76855h15.50781v14.0249H465.81252v16.1499h17.91406v15.29981Z"/>
          <path className="cls-1" d="M520.09182,287.19505l-2.585-13.70606H503.336l-2.585,13.70606H486.49025l15.33008-75.22461h17.915l15.24024,75.22461Zm-9.44825-58.86182h-.35644l-5.34766,31.981h10.78516Z"/>
          <path className="cls-1" d="M566.84084,287.19505l-8.43848-29.9624h-4.1748v29.9624H540.502V211.97044h20.18555q9.15821,0,14.22851,5.68457,5.06836,5.68506,5.06934,16.94678a31.706,31.706,0,0,1-.79883,7.4375,22.901,22.901,0,0,1-2.08789,5.63086,15.16751,15.16751,0,0,1-2.88672,3.82519,10.92321,10.92321,0,0,1-3.1084,2.125l10.75391,33.57471Zm-.84863-51.63721a13.7068,13.7068,0,0,0-.668-4.6748,7.803,7.803,0,0,0-1.73828-2.86865,5.51269,5.51269,0,0,0-2.36231-1.43457,9.18541,9.18541,0,0,0-2.54-.37159h-4.45605v19.01856H558.417a7.24759,7.24759,0,0,0,5.30274-2.28467Q565.99171,240.65917,565.99221,235.55784Z"/>
          <path className="cls-1" d="M614.833,229.927a11.871,11.871,0,0,0-3.74317-3.45312,8.87467,8.87467,0,0,0-4.45605-1.22217,6.14273,6.14273,0,0,0-4.32324,1.80957q-1.91748,1.81054-1.916,5.96a7.42129,7.42129,0,0,0,1.96094,5.48,31.36606,31.36606,0,0,0,5.793,4.31153q2.15185,1.27734,4.53027,3.0332a22.10089,22.10089,0,0,1,4.35156,4.30957,22.61532,22.61532,0,0,1,3.27637,6.11963,24.73751,24.73751,0,0,1,1.2998,8.46142,32.41673,32.41673,0,0,1-1.61523,10.69629,22.17358,22.17358,0,0,1-4.35059,7.66162,18.09735,18.09735,0,0,1-6.3164,4.57715,18.58583,18.58583,0,0,1-7.415,1.542,21.33567,21.33567,0,0,1-10.38281-2.5498,25.72942,25.72942,0,0,1-7.53125-5.94971l7.30859-12.75a16.77775,16.77775,0,0,0,4.72363,4.09033,10.76539,10.76539,0,0,0,5.43653,1.54053,5.60729,5.60729,0,0,0,4.36718-2.01855,8.21763,8.21763,0,0,0,1.78223-5.63135,8.05274,8.05274,0,0,0-2.13867-5.89648,31.43538,31.43538,0,0,0-6.23926-4.62208A39.13564,39.13564,0,0,1,594.378,252.133a19.65748,19.65748,0,0,1-4.01074-4.35645,20.59435,20.59435,0,0,1-2.71778-5.9497,29.283,29.283,0,0,1-.98047-8.0752,30.04238,30.04238,0,0,1,1.73731-10.78418,21.37321,21.37321,0,0,1,4.5459-7.38428,18.33722,18.33722,0,0,1,13.14648-5.63134,20.83613,20.83613,0,0,1,9.26953,2.00537,19.66769,19.66769,0,0,1,6.86231,5.48681Z"/>
          <path className="cls-1" d="M673.74611,229.927a11.87081,11.87081,0,0,0-3.74316-3.45312,8.87468,8.87468,0,0,0-4.45606-1.22217,6.14274,6.14274,0,0,0-4.32324,1.80957q-1.91748,1.81054-1.916,5.96a7.42133,7.42133,0,0,0,1.96093,5.48,31.36675,31.36675,0,0,0,5.793,4.31153q2.15185,1.27734,4.53028,3.0332a22.10089,22.10089,0,0,1,4.35156,4.30957,22.61587,22.61587,0,0,1,3.27637,6.11963,24.73751,24.73751,0,0,1,1.2998,8.46142,32.417,32.417,0,0,1-1.61523,10.69629,22.17374,22.17374,0,0,1-4.35059,7.66162,18.09747,18.09747,0,0,1-6.31641,4.57715,18.58583,18.58583,0,0,1-7.415,1.542,21.33569,21.33569,0,0,1-10.38281-2.5498,25.72955,25.72955,0,0,1-7.53125-5.94971l7.3086-12.75a16.77775,16.77775,0,0,0,4.72363,4.09033A10.76539,10.76539,0,0,0,660.377,273.595a5.6073,5.6073,0,0,0,4.36719-2.01855,8.21763,8.21763,0,0,0,1.78223-5.63135,8.0527,8.0527,0,0,0-2.13868-5.89648,31.43527,31.43527,0,0,0-6.23925-4.62208A39.13575,39.13575,0,0,1,653.291,252.133a19.65772,19.65772,0,0,1-4.01074-4.35645,20.59434,20.59434,0,0,1-2.71777-5.9497,29.283,29.283,0,0,1-.98047-8.0752,30.04216,30.04216,0,0,1,1.73731-10.78418,21.373,21.373,0,0,1,4.54589-7.38428,18.33722,18.33722,0,0,1,13.14649-5.63134,20.83616,20.83616,0,0,1,9.26953,2.00537,19.66766,19.66766,0,0,1,6.8623,5.48681Z"/>
          <path className="cls-1" d="M708.06057,226.42064v60.77441H694.0674V226.42064H682.48146v-14.4502h37.166v14.4502Z"/>
          <path className="cls-1" d="M751.51174,287.19505l-8.43848-29.9624h-4.1748v29.9624H725.17287V211.97044h20.18555q9.1582,0,14.22851,5.68457,5.06836,5.68506,5.06934,16.94678a31.706,31.706,0,0,1-.79883,7.4375,22.901,22.901,0,0,1-2.08789,5.63086,15.16751,15.16751,0,0,1-2.88672,3.82519,10.92321,10.92321,0,0,1-3.1084,2.125l10.75391,33.57471Zm-.84863-51.63721a13.7068,13.7068,0,0,0-.668-4.6748,7.803,7.803,0,0,0-1.73828-2.86865,5.51275,5.51275,0,0,0-2.36231-1.43457,9.18541,9.18541,0,0,0-2.54-.37159h-4.45605v19.01856h4.18945a7.24757,7.24757,0,0,0,5.30273-2.28467Q750.66262,240.65917,750.66311,235.55784Z"/>
          <path className="cls-1" d="M819.29006,249.37034a73.08161,73.08161,0,0,1-1.69336,16.30908,43.174,43.174,0,0,1-4.8125,12.59082,23.94955,23.94955,0,0,1-7.62012,8.07471,19.27158,19.27158,0,0,1-20.23242,0,23.91177,23.91177,0,0,1-7.62012-8.07471,43.09163,43.09163,0,0,1-4.81347-12.59082,79.8928,79.8928,0,0,1-.04395-32.5122,41.67594,41.67594,0,0,1,4.76758-12.43115,23.56468,23.56468,0,0,1,7.62109-7.96875,19.74186,19.74186,0,0,1,20.32129,0,23.59909,23.59909,0,0,1,7.62012,7.96875,42.54611,42.54611,0,0,1,4.8125,12.43115A71.674,71.674,0,0,1,819.29006,249.37034Zm-14.61621,0a62.05366,62.05366,0,0,0-.624-9.08447,33.856,33.856,0,0,0-1.82714-7.27783,13.6493,13.6493,0,0,0-2.98633-4.83447,5.782,5.782,0,0,0-4.18848-1.75293,5.92845,5.92845,0,0,0-4.2334,1.75293,13.2883,13.2883,0,0,0-3.03125,4.83447,32.21681,32.21681,0,0,0-1.78222,7.27783,66.53831,66.53831,0,0,0-.57911,9.08447,67.66892,67.66892,0,0,0,.57911,9.13721,32.2141,32.2141,0,0,0,1.78222,7.33154,13.83192,13.83192,0,0,0,3.03125,4.88721,5.83432,5.83432,0,0,0,4.2334,1.80615,5.692,5.692,0,0,0,4.18848-1.80615,14.22167,14.22167,0,0,0,2.98633-4.88721,33.8188,33.8188,0,0,0,1.82714-7.33154A63.10819,63.10819,0,0,0,804.67385,249.37034Z"/>
          <path className="cls-1" d="M855.209,287.19505l-14.61718-45.79346h-.26758l.35644,45.79346H827.93557V211.97044h15.68652l14.52734,46.43115h.26758l-.26758-46.43115h12.29981v75.22461Z"/>
          <path className="cls-1" d="M914.47853,287.19505a27.217,27.217,0,0,1-10.07128,1.70019,19.95991,19.95991,0,0,1-10.65039-2.86914,24.56711,24.56711,0,0,1-7.93262-8.02148,40.96934,40.96934,0,0,1-4.99121-12.37793,68.62363,68.62363,0,0,1-1.73828-16.04395,69.31944,69.31944,0,0,1,1.73828-16.09667,43.23043,43.23043,0,0,1,4.94629-12.53711,24.5893,24.5893,0,0,1,7.71-8.12842,18.26388,18.26388,0,0,1,10.02636-2.86865,19.69919,19.69919,0,0,1,17.5586,9.13769l-7.75391,12.43115q-3.22411-5.31225-8.77344-5.3125a7.54427,7.54427,0,0,0-5.10253,1.85938,14.2741,14.2741,0,0,0-3.582,5.04687,29.23694,29.23694,0,0,0-2.10351,7.437,57.82111,57.82111,0,0,0-.6709,9.03125,61.90346,61.90346,0,0,0,.627,9.03125,29.29965,29.29965,0,0,0,2.0166,7.4375,14.70107,14.70107,0,0,0,3.49512,5.1001,8.13786,8.13786,0,0,0,10.083.31836V257.44554h-6.77441v-14.769h19.252v40.05616A23.67859,23.67859,0,0,1,914.47853,287.19505Z"/>
        </g>
      </g>
    </g>
  </g>
</Logo>
    )
}

export default TwentyYearsStrong;
