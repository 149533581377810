import React from "react"
import { Box, Link } from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"
import ValleyAdvocatesLogo from "./ValleyAdvocatesLogo"
import WatchdoggingLink from "./WatchdoggingLink"
import GrandTargheeLink from "./GrandTargheeLink"
import CommunityCollaborationLink from "./CommunityCollaborationLink"
import HoaServicesLink from "./HoaServicesLink"
import LegacyLink from "./LegacyLink"
import DonateButton from '../Buttons/DonateButton'

const SidebarNav = props => {
  return (
    <>
      <Box
        //maxWidth={{xl: "16rem"}}
        width="16rem"
        maxHeight="calc(100vh - 1rem)"
        position="fixed"
        top="0"
        bottom="0"
        overflowY="auto"
        marginTop="20"
        display={{ sm: "none", md: "none", lg: "block" }}
        backgroundColor="brand.400"
        className="sidebar-nav"
      >
        <Box
          backgroundColor="brand.400"
          padding="4"
          color="white"
          height="100%"
        >
          <Box w="224px">
            <ValleyAdvocatesLogo width="200px" />
          </Box>
          <Box marginTop="8" marginBottom="8" marginLeft="6">
          <Box className="sidebar-nav-linkbox">
              <Link
                as={GatsbyLink}
                className="sidebar-nav-item"
                to="/about"
                activeClassName="active"
              >
                About
              </Link>
              {props.pathName.includes("about") && (
                <>
                  <Box ml="2" mb="2">
                    <Link
                      as={GatsbyLink}
                      className="sidebar-nav-sub-item chakra-link sidebar-nav-item"
                      activeClassName="active"
                      to="/about/timeline" 
                    >
                      Timeline
                    </Link>
                    </Box>
                    <Box ml="2" mb="2">
                    <Link
                      as={GatsbyLink}
                      className="sidebar-nav-sub-item chakra-link sidebar-nav-item"
                      activeClassName="active"
                      to="/about/staff-and-board" 
                    >
                      Staff &amp; Board
                    </Link>
                    </Box>
                </>
              )}
            </Box>
            <Box className="sidebar-nav-linkbox">
              <Link
                as={GatsbyLink}
                className="sidebar-nav-item"
                to="/our-work"
                activeClassName="active"
              >
                Our Work
              </Link>
              {props.pathName.includes("our-work") && (
                <>
                  <Box ml="2" mb="2">
                    <GrandTargheeLink
                      to="/our-work/featured-project"
                    >                    
                      Featured Project                   
                    </GrandTargheeLink>
                  </Box>
                  <Box ml="2" mb="2">
                    <WatchdoggingLink
                      to="/our-work/watchdogging"  
                    >
                      Watchdogging
                    </WatchdoggingLink>
                  </Box>
                  <Box ml="2" mb="2">
                    <CommunityCollaborationLink
                      to="/our-work/community-collaboration"
                    >
                      Community Collaboration
                    </CommunityCollaborationLink>
                  </Box>
                  <Box ml="2" mb="2">
                    <HoaServicesLink
                      to="/our-work/hoa-services"

                    >
                      HOA Services
                    </HoaServicesLink>
                  </Box>
                  <Box ml="2" mb="2">
                    <LegacyLink
                      to="/our-work/legacy"
                    >
                      Legacy
                    </LegacyLink>
                  </Box>
                </>
              )}
            </Box>
            <Box className="sidebar-nav-linkbox">
              <Link
                as={GatsbyLink}
                className="sidebar-nav-item"
                to="/news"
                activeClassName="active"
              >
                Latest News
              </Link>
            </Box>
           <Box className="sidebar-nav-linkbox">
              <Link
                as={GatsbyLink}
                className="sidebar-nav-item"
                to="/action"
                activeClassName="active"
              >
                Action
              </Link>
            </Box>
            <Box className="sidebar-nav-linkbox">
              <Link
                as={GatsbyLink}
                className="sidebar-nav-item"
                to="/resources"
                activeClassName="active"
              >
                Resources
              </Link>
            </Box>
            <Box className="sidebar-nav-linkbox">
              <Link
                as={GatsbyLink}
                className="sidebar-nav-item"
                to="/contact"
                activeClassName="active"
              >
                Contact
              </Link>
            </Box>
            <Box mt="8" pr="4" pb="4">
                    <DonateButton 
                        width="100%"
                        btnText="Donate"
                        colorScheme="steel"
                    />
                </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SidebarNav
