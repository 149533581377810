import React from 'react'
import { Button } from '@chakra-ui/react'

const CtaButton = (props) => (
    <>
    <Button
        onClick={props.onClick} 
        width={props.width}
        size={props.size}
        colorScheme={props.colorScheme}
        textTransform="uppercase"
        >
        {props.btnText}
    </Button>
    </>  
)

export default CtaButton