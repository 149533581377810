import React from 'react'
import { Link } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

const isActive = ({ location }) => {
    if (location.pathname.includes('hoa-services')) {
        return {className: 'active chakra-link sidebar-nav-sub-item'}
    }
    return null
}

const HoaServicesLink = ({ children, ...props }) => {
    return (
    <Link as={GatsbyLink} getProps={isActive} {...props}
    fontSize="1.125rem"
    className="chakra-link sidebar-nav-item sidebar-nav-sub-item">
        {children}
    </Link>
    )
}

export default HoaServicesLink