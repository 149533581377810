import React from "react"
import { Box } from "@chakra-ui/react"

const MainContentBox = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    w={{ md: "100%", lg: "75%", xl: '80%' }}
    marginLeft={{ md: "none", lg: "16rem"}}
    h="100%"
  >
    {children}
  </Box>
)

export default MainContentBox
