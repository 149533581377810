import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import {
    Link,
    Box,
    Text,
    Flex
} from '@chakra-ui/react'
import PixelBurst from '../../animations/wpop.json';

const CalderaCreative = () => {
    const lottieContainer = useRef(null);
    const lottieContainerMobile = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: lottieContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: PixelBurst,
        });
        lottie.loadAnimation({
            container: lottieContainerMobile.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: PixelBurst,
        });

        return () => {
            lottie.destroy();
        };
    }, []);

    return (
        <>
        <Box pr="8" position="relative"  pr="8">
            <Box bottom="-4rem" left="4.25rem" ref={lottieContainer} h="10rem" w="10rem" position="absolute"/>
                <Box zIndex="100" position="relative">
                    <Text color="white" mb="0" whiteSpace="nowrap">
                        Website by <Link href="https://calderacreative.xyz" target="_blank" rel="noopener noreferrer" _hover={{color: 'sunset.500'}} color="sunset.300" variant="sub-footer-nav-link" onMouseEnter={() => lottie.play()} onMouseLeave={() => lottie.stop()}>Caldera Creative</Link>
                    </Text>
                </Box>
        </Box>
        {/* <Box display={{base: 'block', md: 'none'}} position="relative">
            <Box position="absolute" bottom="0" right="20px" ref={lottieContainerMobile} h="2rem" w="5.25rem"/>
            <Box display="block">
                <Box pb="2">
                    <Text color="white" mb="0" whiteSpace="nowrap" textAlign="center">
                        Website by <Link href="https://calderacreative.xyz" target="_blank" rel="noopener noreferrer" _hover={{color: 'happy-yellow.500'}} variant="sub-footer-nav-link" onMouseEnter={() => lottie.play()} onMouseLeave={() => lottie.stop()}>Caldera Creative</Link>
                    </Text>
                </Box>
            </Box>
        </Box> */}
    </>
    )
}

export default CalderaCreative;