import React from 'react'
import { Button, Tooltip } from '@chakra-ui/react'

const CtaButton = (props) => (
    <>
    <a href="https://donatenow.networkforgood.org/TetonValleyAdvocates?code=Homepage%20donations" rel="noopener noreferrer" target="_blank">
        <Tooltip hasArrow label="Opens Network For Good in a new tab">
        <Button 
        width={props.width}
        size={props.size}
        colorScheme={props.colorScheme}
        textTransform="uppercase"
        >
        {props.btnText}
    </Button>
        </Tooltip>
    </a>
    </>  
)

export default CtaButton