import React, { useState } from "react"
import { Box, Link, Flex, Text, CloseButton, IconButton } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink } from "gatsby"
import { motion } from "framer-motion"

import ValleyAdvocatesLogoWide from './ValleyAdvocatesLogoWide'

const MotionBox = motion(Box)

const MobileMenu = (props) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => (
      setShow(!show)
  )

  return (
  <Box display={{ sm: 'block', md: 'block', lg: 'none' }} position="fixed" top="0" className="mobile-header">
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingLeft=".5rem"
      paddingRight="0.5rem"
      bg="#2d3d00"  
      color="white"
      {...props}
      display="flex"
    >
      
      <Flex w="75%">
          <MotionBox
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.5 }}
            p={3}
          >
            
            <ValleyAdvocatesLogoWide width="200px"/>
          
          </MotionBox>
      </Flex>
    
    <div>
      <Box onClick={handleToggle}>
          { show ? <CloseButton /> : 
         ( <IconButton
            aria-label="Menu"
            icon={<HamburgerIcon />}
            variant="outline"
            size="sm"
        />)
  }      </Box>
</div>    
    <Box
        display={{ sm: show ? "block" : "none"}}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        paddingBottom="1rem"
    >
      <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center" className="sidebar-nav-item">
        <Link to="/about" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            About
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center" className="sidebar-nav-item">
        <Link to="/our-work" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Our Work
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center" className="sidebar-nav-item">
        <Link to="/news" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Latest News
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center" className="sidebar-nav-item">
        <Link to="/action" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Action
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center" className="sidebar-nav-item">
        <Link to="/resources" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Resources
        </Link>
        </Text>
       
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block" textAlign="center" className="sidebar-nav-item">
        <Link to="/contact" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Contact
        </Link>
        </Text>
   </Box>
    </Flex>
    </Box>
  )
}

export default MobileMenu